<template>
  <div>
    <div>
      <Header></Header>
    </div>
    <div id="admindiv1">
      <div style="margin-top: 15px;font-family: '苹方',serif;font-size: 13px;margin-left: 10px">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item style="font-size: 13px" :to="{ path: '/manage' }" @click="reall()">首页</el-breadcrumb-item>
          <el-breadcrumb-item style="font-size: 13px">在线文档管理</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>

    <div id="forms_div2">
      <el-tabs v-model="activeName" tab-position="left" @tab-click="handleClick">
        <el-tab-pane label="新增在线文档" name="a"><Add_online></Add_online></el-tab-pane>
        <el-tab-pane label="管理在线文档" name="b"><Manage_online></Manage_online></el-tab-pane>
        <el-tab-pane label="表格配置管理" name="c"><File_dispose></File_dispose></el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>

import Header from "@/views/header";
import Add_online from "@/views/online_document/manage/add_online";
import Manage_online from "@/views/online_document/manage/manage_online";
import File_dispose from "@/views/online_document/dispose/file_dispose";


export default {
  components: {Header, Add_online, Manage_online, File_dispose},
  name: "online_document",
  data(){
    return{
      activeName: 'a'
    }
  },
  created() {
    if (!this.$route.params.key){
      this.$router.push("/manage")
    }
    if (sessionStorage.getItem('online')){
      this.activeName = sessionStorage.getItem('online');
    }
  },
  methods:{
    handleClick(tab, event){
      //console.log(tab)
      // 点击tab后触发事件，修改显示页面，将状态保存在sessionStorage里面
      sessionStorage.setItem('online', tab.props.name)
    },
    reall(){
      sessionStorage.removeItem('online');
    },
  }
}
</script>

<style scoped>
#admindiv1{
  border-radius: 5px;
  background-color: white;
  display: flex;
  width: 95%;
  height: 40px;
  margin: 10px auto 30px;
  justify-content: space-between;
}

#forms_div2{
  border-radius: 5px;
  margin: 0 auto;
  background-color: white;
  width: 95%;
  padding-top: 20px;
  padding-bottom: 20px;
  min-height: 500px;
}
</style>
