<template>
  <div style="width: 85%">
    <div style="width: 35%;margin: 5% auto;">
    <!--  部门选择-->
      <div>
        <el-tag style="margin-right: 5%">&nbsp;部&nbsp;&nbsp;&nbsp;&nbsp;门&nbsp;</el-tag>
        <el-select v-model="department" clearable placeholder="请选择部门" size="small" style="width: 80%;">
          <el-option
              v-for="item in departments"
              :key="item.id"
              :label="item.departmentname"
              :value="item.id"
          />
        </el-select>
      </div>
      <!--工区选择-->
      <div style="margin-top: 10%">
        <el-tag style="margin-right: 5%">&nbsp;工&nbsp;&nbsp;&nbsp;&nbsp;区&nbsp;</el-tag>
        <el-select v-model="bdst" clearable placeholder="请选择工区" size="small" style="width: 80%;">
          <el-option
              v-for="item in bdsts"
              :key="item.value"
              :label="item.label"
              :value="item.value"
          />
        </el-select>
      </div>
      <!--在线文档名称-->
      <div style="margin-top: 10%;display: flex">
        <el-tag style="margin-right: 5%">文档名称</el-tag>
        <el-input v-model="document_name" placeholder="请输入在线文档名称" clearable size="small" />
      </div>
      <!--在线文档地址-->
      <div style="margin-top: 10%;display: flex">
        <el-tag style="margin-right: 5%">文档地址</el-tag>
        <el-input v-model="document_url" placeholder="请输入在线文档地址" clearable size="small" />
      </div>
    </div>
  <!--  提交按钮-->
    <div style="float: right;margin-right: 30%">
      <el-button type="primary" style="width: 200%" @click="saveOnlineDocument()">提&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;交</el-button>
    </div>
  </div>
</template>

<script>
import {Decrypt, Encrypt} from '@/utils/secret';
import { ElMessage } from 'element-plus'

export default {
  inject: ['reload'],
  name: "add_online",
  data(){
    return{
      department: '',
      departments: [],
      bdst: '',
      bdsts: [
        {
          value: '1',
          label: '共用',
        },
        {
          value: '21',
          label: '2标一工区',
        },
        {
          value: '22',
          label: '2标二工区',
        },
        {
          value: '51',
          label: '5标一工区',
        },
        {
          value: '52',
          label: '5标二工区',
        },
        {
          value: '53',
          label: '5标三工区',
        },
        {
          value: '54',
          label: '5标四工区',
        },
        {
          value: '55',
          label: '5标五工区',
        },
        {
          value: '56',
          label: '5标六工区',
        },
        {
          value: '57',
          label: '5标七工区',
        },
      ],
      document_url: '',
      document_name: ''
    }
  },
  created() {
    this.getDepartment();
  },
  methods:{
    getDepartment(){
      this.axios.get('/userinfo/getdepart', (response) => {
        this.departments = response.obj;
      },{
        type: 1
      })
    },
    saveOnlineDocument(){
      this.axios.post('/onlineDocument/saveOnlineDocument', (response) => {
        if (response.obj){
          this.reload();
          ElMessage({
            message: '提交成功!',
            type: 'success',
          })
        }else {
          ElMessage.error('提交有误，请联系科信部!')
        }
      },{
        department_id: this.department,
        bdst_id: this.bdst,
        document_url: Encrypt(this.document_url),
        document_name: this.document_name
      })
    }
  }
}
</script>

<style scoped>

</style>