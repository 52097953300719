<template>
  <div>
    <!--筛选条件-->
    <div>
      <el-select v-model="department" clearable placeholder="请选择部门" size="small" style="width: 80%;" @change="getOnlineDocumentByDepart()">
        <el-option
            v-for="item in departments"
            :key="item.id"
            :label="item.departmentname"
            :value="item.id"
        />
      </el-select>
    </div>
  <!--  文档列表-->
    <div style="margin-top: 3%">
      <el-table :data="online_document_list" stripe style="width: 100%">
        <el-table-column prop="departmentname" label="部门"  />
        <el-table-column prop="bdst" label="所用工区"  />
        <el-table-column prop="documentName" label="文档名称" />
        <el-table-column fixed="right" label="操作">
          <template #default="scope">
            <el-button link type="primary" size="small" @click="getOnlineDocumentUrl(scope.row.documentUrl)">查看</el-button>
            <el-popconfirm

                confirm-button-text="确定"
                cancel-button-text="取消"
                icon-color="#626AEF"
                title="是否确定删除?"
                @confirm="delOnlineDocumentById(scope.row.id)"
            >
              <template #reference>
                <el-button>删除</el-button>
              </template>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div>
      <el-button @click="autoDocument()">授权</el-button>
    </div>
  </div>
</template>

<script>
import {Decrypt, Encrypt} from '@/utils/secret';
import { ElMessage } from 'element-plus'

export default {
  inject: ['reload'],
  name: "manage_online",
  data(){
    return{
      online_document_list: [],
      department: '',
      departments: []
    }
  },
  created() {
    this.getDepartment();
    this.getOnlineDocumentList();
  },
  methods:{
    getDepartment(){
      this.axios.get('/userinfo/getdepart', (response) => {
        this.departments = response.obj;
      },{
        type: 1
      })
    },
    getOnlineDocumentList(){
      this.axios.get('/onlineDocument/getOnlineDocumentList', (response) => {
        this.online_document_list = response.obj;
        for (let i = 0; i < this.online_document_list.length; i++){
          this.online_document_list[i].documentUrl = Decrypt(this.online_document_list[i].documentUrl);
        }
      })
    },
    getOnlineDocumentByDepart(){
      this.axios.post('/onlineDocument/getOnlineDocumentByDepart', (response) => {
        this.online_document_list = response.obj;
        for (let i = 0; i < this.online_document_list.length; i++){
          this.online_document_list[i].documentUrl = Decrypt(this.online_document_list[i].documentUrl);
        }
      },{
        department_id: this.department
      })
    },
    delOnlineDocumentById(id){
      this.axios.post('/onlineDocument/delOnlineDocument', (response) => {
        if (response.obj){
          this.reload();
          ElMessage({
            message: '删除成功!',
            type: 'success',
          })
        }
      },{
        id: id
      })
    },
    getOnlineDocumentUrl(url){
      this.$router.push({
        name: 'View_online',
        params: {
          online_document_url: url,
          flag: 1
        }
      });
    },
    autoDocument(){
      window.open("https://developer.kdocs.cn/h5/auth?app_id=AK20221227MVYYGL&scope=user_basic,access_personal_files&redirect_uri=https://www.cr12cz.cn/auto");
    }
  }
}
</script>

<style scoped>

</style>